<template>
  <div class="integration-main" id="C4">
    <h2>Connect to your favorite apps</h2>
    <div class="all-integrations pt-lg-40">
      <h3 class="weight-400">All Integrations</h3>
      <div class="row">
        <div
          class="col-lg-4 col-md-6"
          v-for="(integrations, index) in possibleIntegrations"
          :key="index"
        >
          <div class="integration-card">
            <div class="title">
              <div class="flex">
                <img
                  :src="
                    require(`@/assets/images/icons/${integrations.integrationimage}`)
                  "
                  alt=""
                />
                <span class="blue-2-text font-lg-16 weight-600">{{
                  integrations.name
                }}</span>
              </div>
              <!-- <div
                v-if="
                  integrations.key == INTEGRATED_API_IDS.hubspot &&
                    integrations.detectChanges
                "
              >
                <button
                  class="button"
                  v-on:click="openJobChangePopUp(integrations)"
                >
                  Detect Job Change
                </button>
              </div> -->

              <div v-if="integrations.key == INTEGRATED_API_IDS.hubspot"></div>

              <div
                v-if="
                  integrations.key == INTEGRATED_API_IDS.hubspot &&
                    integrations.name == 'HubSpot for Enrich'
                "
              >
                <!-- <button
                  class="button"
                  v-on:click="openEnrichmentPopUp(integrations)"
                >
                  Start Enrichment old
                </button> -->
                <button
                  class="button"
                  v-on:click="openEnrichmentSettingsPopUp(integrations)"
                >
                  Start Enrichment
                </button>
              </div>
            </div>

            <div class="integration-card-body pt-lg-16">
              <p class="font-lg-14">
                {{ integrations.paragraph }}
              </p>
            </div>
            <div class="integration-action-btn pt-lg-24">
              <div
                class="action-text"
                v-if="
                  integrations.key == INTEGRATED_API_IDS.zapier ||
                    integrations.key == INTEGRATED_API_IDS.hubspot_toggle ||
                    integrations.key == INTEGRATED_API_IDS.integromat ||
                    integrations.key == INTEGRATED_API_IDS.captaindata ||
                    integrations.key == INTEGRATED_API_IDS.leadjet ||
                    integrations.key == INTEGRATED_API_IDS.linkedin ||
                    integrations.key == INTEGRATED_API_IDS.n8n ||
                    integrations.key == INTEGRATED_API_IDS.clay
                "
              >
                <h4
                  class="font-lg-16 weight-600"
                  role="button"
                  @click="navigateToDiscover(integrations.key)"
                >
                  {{ integrations.connected }}
                </h4>
              </div>
              <div
                class="action-text"
                v-if="
                  integrations.chacked &&
                    (integrations.key == INTEGRATED_API_IDS.hubspot ||
                      integrations.key == INTEGRATED_API_IDS.pipedrive)
                "
              >
                <h4 class="font-lg-16 weight-600">
                  {{ integrations.connected }}
                </h4>
              </div>
              <div
                class="action-text"
                v-if="
                  !integrations.chacked &&
                    (integrations.key == INTEGRATED_API_IDS.hubspot ||
                      integrations.key == INTEGRATED_API_IDS.pipedrive)
                "
              >
                <h4 class="font-lg-16 weight-600">
                  {{ integrations.disconnected }}
                </h4>
              </div>

              <div>
                <div v-if="integrations.chacked !== true">
                  <div
                    class="on-off-btn"
                    @click="onSelectIntegration(integrations)"
                    v-if="
                      integrations.key == INTEGRATED_API_IDS.hubspot ||
                        integrations.key == INTEGRATED_API_IDS.pipedrive
                    "
                  >
                    <b-form-checkbox
                      v-model="integrations.chacked"
                      name="check-button"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="on-off-btn"
                    @click="onSelectIntegrationModalToggle(integrations)"
                    v-if="
                      integrations.key == INTEGRATED_API_IDS.hubspot ||
                        integrations.key == INTEGRATED_API_IDS.pipedrive
                    "
                  >
                    <b-form-checkbox
                      v-model="integrations.chacked"
                      name="check-button"
                      switch
                      size="lg"
                      :disabled="true"
                    ></b-form-checkbox>
                    <!-- <h1>fsdfsdfsd</h1> -->
                  </div>
                </div>
              </div>

              <div
                v-if="integrations.key == INTEGRATED_API_IDS.hubspot_toggle"
                class="on-off-btn "
              >
                <b-form-checkbox
                  v-model="task_creation_optional"
                  name="check-button"
                  @change="showDataSwicth(task_creation_optional)"
                  switch
                  size="lg"
                ></b-form-checkbox>
              </div>
              <div
                v-if="showTaskForm && integrations.key == 'hubspot_toggle'"
                class="view_info"
              >
                <!-- <h4 class="font-lg-16 weight-600">
                  Click to Customise Your Data
                </h4>
                <b-form-checkbox v-model="enrichmentPermission" name="check-button" @change="openJobChangePopUp()" switch
                  size="lg"></b-form-checkbox> -->
                <a
                  target="_blank"
                  class="font-lg-14 text-blue"
                  @click="openJobChangePopUp()"
                  style="cursor: pointer;"
                  >Customise Your Task</a
                >
              </div>
            </div>
            <div>
              <a
                target="_blank"
                :href="integrations.actualLink"
                class="font-lg-14 text-blue"
              >
                {{ integrations.linkText }}
              </a>
            </div>
          </div>
        </div>
        <modal name="my-modal" class="modaldata">
          <div class="enrich-head">
            <i class="fa fa-times" aria-hidden="true" @click="closeModal"></i>
          </div>
          <div class="enrichmodal newenrichmodal">
            <h4 style="text-align: center;  ">
              <span class="text-color">Are you sure,</span><br />
              You want to Disconnect Hubspot
            </h4>

            <div class="action_button" style="text-align: center;">
              <button
                @click="closeModal"
                class="action_perform secondary-btn-outline"
              >
                No
              </button>
              <button
                @click="disconnecthubspot"
                class="action_perform secondary-btn-outline"
              >
                Yes
              </button>
            </div>
          </div>
        </modal>
      </div>
      <div v-if="hubspotIntegrated && !pushHubspot && !hubspotJobChangeDetect">
        <div class="company-new">
          <div class="company-hadding">
            <h4>Choose fields for enrichment</h4>
          </div>
          <!-- <div class="main-popup">
            <div class="start-test-content">
              <div class="start-test">
                <h5>Auto enrich HubSpot contact</h5>
              </div>

              <div class="start-test-button">
                <label class="switch">
                  <b-form-checkbox name="check-button-authEnrich" v-model="autoEnrich" switch size="lg"></b-form-checkbox>
                </label>
              </div>
            </div>
            <div class="add-text">
              <p>
                This feature allow you to automatically add details about a
                person and his company in HubSpot
              </p>
            </div>
          </div> -->

          <div>
            <div class="main-popup">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>Enrich All Contacts</h5>
                </div>
                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-authEnrich"
                      v-model="enrichAllHubspotContact"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
              <div class="or-btn text-center" v-if="!enrichAllHubspotContact">
                <span>OR</span>
              </div>

              <div class="enrich-list" v-if="!enrichAllHubspotContact">
                <label>Enrich Contact By List</label>
                <div v-if="hubspotContactList && hubspotContactList.length > 0">
                  <v-select
                    v-model="selectedContactList"
                    label="name"
                    placeholder="Select List"
                    :reduce="(option) => option.listId"
                    :options="hubspotContactList"
                    multiple
                  ></v-select>
                </div>
                <div v-else>
                  <span> No Contact list Found</span>
                </div>
              </div>
            </div>

            <div class="main-popup mt-3">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>Enrich Already Enriched Contacts</h5>
                </div>

                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-companyPremium"
                      v-model="enrichAlreadyEnrichedContacts"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
            </div>

            <div class="main-popup mt-3" v-if="!enrichFutureContact">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>Enrich all existing contacts</h5>
                </div>

                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-companyPremium"
                      v-model="enrichAllExistingContact"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
            </div>
            <div class="main-popup mt-3" v-if="!enrichAllExistingContact">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>
                    Enrich all existing contacts and future contacts created
                  </h5>
                </div>
                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-companyPremium"
                      v-model="enrichFutureContact"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
              <div class="add-text">
                <p>
                  If a contact is added in the future, we will continue to
                  enrich them
                </p>
              </div>
            </div>
            <div class="main-popup mt-3">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>Company technology</h5>
                </div>

                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-companyPremium"
                      v-model="deepTraffic"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
            </div>

            <div class="main-popup mt-3">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>Company Basic infos</h5>
                </div>
                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-companyPremium"
                      v-model="companyPremium"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
              <div class="add-text">
                <p>
                  Add company informations (Exact number of employees, industry,
                  tags, description, locations…)
                </p>
              </div>
            </div>

            <div class="main-popup">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>Company French infos</h5>
                </div>

                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-companyFrench"
                      v-model="companyFrench"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
              <div class="add-text">
                <p>
                  For french company add SIRENE informations
                </p>
              </div>
            </div>

            <div class="main-popup">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>Company Full</h5>
                </div>

                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-companyFull"
                      v-model="companyFull"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
              <div class="add-text">
                <p>
                  Add financial informations (money raised, funding stage) and
                  website infos (technology, website traffic)
                </p>
              </div>
            </div>
            <div class="main-popup">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>Create and associate a new company</h5>
                </div>
                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-companyFull"
                      v-model="createAssociateNewComapanyIfJobChangeDetect"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
              <div class="add-text">
                <p>
                  Create and associate a new company if a job change is detected
                </p>
              </div>
            </div>
            <div class="main-popup mt-3">
              <div class="start-test-content">
                <div class="start-test">
                  <h5>Do You Want to Create Task</h5>
                </div>

                <div class="start-test-button">
                  <label class="switch">
                    <b-form-checkbox
                      name="check-button-companyPremium"
                      v-model="taskCreationOptional"
                      switch
                      size="lg"
                    ></b-form-checkbox>
                  </label>
                </div>
              </div>
            </div>
            <div v-if="taskCreationOptional">
              <div class="">
                <div class="company-hadding">
                  <h4>Customize your task</h4>
                </div>

                <form>
                  <div class="heading-login">
                    <p>
                      Elevate user engagement with personalized content,
                      utilizing liquid variables like ((contact_datagma_name)),
                      ((contact_datagma_linkedin_url)),
                      ((contact_datagma_former_company)) and
                      ((contact_datagma_new_company)).
                    </p>
                  </div>
                  <div class="form-group">
                    <label>Task Subject</label>
                    <input
                      v-model="taskData.taskSubjectData"
                      name="subject"
                      class="form-control"
                      placeholder="Enter your subject"
                      type="text"
                      id="taskSubject"
                    />
                    <div
                      v-if="
                        taskCreationOptional &&
                          submitted &&
                          $v.taskData.taskSubjectData.$error
                      "
                      class="invalid_feedback"
                    >
                      <span v-if="!$v.taskData.taskSubjectData.required"
                        >*This filed is required</span
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Task Body</label>
                    <textarea
                      v-model="taskData.taskBodyData"
                      name="body"
                      class="form-control"
                      placeholder="Enter your body"
                      id="taskBody"
                    ></textarea>

                    <!-- <div
                    v-if="submitted && $v.user.newPassword.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.newPassword.required"
                      >Please enter a valid new password</span
                    >
                  </div> -->
                    <div
                      v-if="
                        taskCreationOptional &&
                          submitted &&
                          $v.taskData.taskBodyData.$error
                      "
                      class="invalid_feedback"
                    >
                      <span v-if="!$v.taskData.taskBodyData.required"
                        >*This filed is required</span
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Task Priority</label>
                    <b-form-select
                      v-model="taskData.taskPriorityData"
                      :options="taskPriority"
                    ></b-form-select>
                    <div
                      v-if="
                        taskCreationOptional &&
                          submitted &&
                          $v.taskData.taskPriorityData.$error
                      "
                      class="invalid_feedback"
                    >
                      <span v-if="!$v.taskData.taskPriorityData.required"
                        >*This filed is required</span
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Task Type</label>
                    <b-form-select
                      v-model="taskData.taskTypeData"
                      :options="taskType"
                    ></b-form-select>
                    <div
                      v-if="
                        taskCreationOptional &&
                          submitted &&
                          $v.taskData.taskTypeData.$error
                      "
                      class="invalid_feedback"
                    >
                      <span v-if="!$v.taskData.taskTypeData.required"
                        >*This filed is required</span
                      >
                    </div>
                  </div>
                  <div class="main-popup mt-3">
                    <div class="start-test-content">
                      <div class="start-test">
                        <h5>Assign to contact’s owner</h5>
                      </div>

                      <div class="start-test-button">
                        <label class="switch">
                          <b-form-checkbox
                            name="check-button-companyPremium"
                            v-model="taskData.taskAssignToOwner"
                            switch
                            size="lg"
                          ></b-form-checkbox>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="start-test-content-new">
            <div class="start-test-cancel">
              <button @click="closePop()">Cancel</button>
            </div>

            <div class="start-test-submit">
              <b-button
                :disabled="isSubmitDisable()"
                @click="startEnrichment()"
              >
                Submit
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <!--====== new popup design ====== -->

      <!-- ////////////////// mine -->

      <div v-if="hubspotSetting && !pushHubspot && !hubspotJobChangeDetect">
        <div class="modal_outer_div" @click="closePopStep()"></div>
        <div class="new-settings-popup">
          <div class="company-new ">
            <!-- <div class="dg-main-wrap"> -->
            <div class="dg-main-step">
              <div class="dg-main-step-head">
                <h1 class="dg-top-heading">Enrichment settings</h1>
                <div class="cross-icon" @click="closePopStep()">
                  <img src="../../../../assets/images/Close.png" />
                </div>
              </div>
              <div class="dg-stet-out-patch">
                <div class="dg-step-inner-patch">
                  <h2>Progress Step</h2>
                  <div class="dg-step-pegination">
                    <span>{{ this.currentIndex + 1 }}</span>
                    <span>of</span>
                    <span>4</span>
                  </div>
                </div>
                <div class="dg-step-to-step">
                  <div
                    v-for="(step, index) in steps"
                    :key="index"
                    class="step"
                    :class="{
                      active: index <= currentIndex,
                      inactive: index > currentIndex,
                    }"
                  ></div>
                </div>

                <div v-if="currentIndex === 0" class="dg-step-1">
                  <div class="dg-main-wrap">
                    <div>
                      <div class="dg-step-content-out">
                        <h4 class="dg-step-title">
                          Step 1: Choose which contacts need to be enriched
                        </h4>
                        <h4 class="dg-step-sub-title">
                          Enrich all your HubSpot contacts or enrich a specific
                          list
                        </h4>
                        <div class="dg-radio-button-wrapper">
                          <div class="dg-radio-button">
                            <input
                              type="radio"
                              id="enrichAll"
                              name="enrichmentOption"
                              :value="true"
                              v-model="enrichAllHubspotContact"
                            />
                            <label for="enrichAll"
                              >Enrich all your HubSpot contacts</label
                            >
                          </div>
                          <div class="dg-radio-button">
                            <input
                              type="radio"
                              id="enrichSpecific"
                              name="enrichmentOption"
                              :value="false"
                              v-model="enrichAllHubspotContact"
                            />
                            <label for="enrichSpecific"
                              >Only enrich specific HubSpot lists</label
                            >
                          </div>
                        </div>
                        <div
                          class="enrich-list"
                          v-if="!enrichAllHubspotContact"
                        >
                          <div class="dg-select-form">
                            <div
                              v-if="
                                hubspotContactList &&
                                  hubspotContactList.length > 0
                              "
                            >
                              <!-- Use v-model for multiple selection -->
                              <v-select
                                v-model="selectedContactList"
                                label="name"
                                placeholder="Select List"
                                :reduce="(option) => option.listId"
                                :options="hubspotContactList"
                                multiple
                              ></v-select>
                            </div>
                            <div v-else>
                              <span>No Contact list Found</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="dg-type-of-radio-button">
                        <h4 class="dg-step-title">
                          What type of enrichment do you want?
                        </h4>
                        <div class="dg-radio-button-wrapper">
                          <div class="dg-radio-button">
                            <input
                              type="radio"
                              id="staticEnrichment"
                              name="listType"
                              value="Static"
                              v-model="listType"
                            />
                            <label for="staticEnrichment">
                              <div>
                                <h4>Static</h4>
                                <span
                                  >Only the contacts existing right now will be
                                  enriched</span
                                >
                              </div>
                            </label>
                          </div>
                          <div class="dg-radio-button">
                            <input
                              type="radio"
                              id="dynamicEnrichment"
                              name="listType"
                              value="Dynamic"
                              v-model="listType"
                            />
                            <label for="dynamicEnrichment">
                              <div>
                                <h4>Dynamic</h4>
                                <span>
                                  The contacts you add in the future will also
                                  be automatically enriched
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="currentIndex === 1" class="dg-step-2">
                  <div class="dg-main-wrap">
                    <div>
                      <div class="dg-step-content-out">
                        <h4 class="dg-step-title">
                          Step 2: Choose the data you want
                        </h4>
                        <div class="dg-step-fill-file-btn">
                          <span
                            ><img src="../../../../assets/images/file.png"
                          /></span>
                          <h4 class="dg-step-sub-title">
                            Click here to see the list of data points added by
                            Datagma in HubSpot
                          </h4>
                        </div>
                        <div class="dg-checbox-button-wrapper">
                          <div class="dg-radio-button">
                            <div class="start-test-button">
                              <!-- <label class="switch"> -->
                              <b-form-checkbox
                                name="check-button-companyPremium"
                                v-model="deepTraffic"
                                size="lg"
                                id="bt"
                              ></b-form-checkbox>
                              <!-- </label> -->
                            </div>
                            <label for="bt">Company Technology</label>
                          </div>
                          <div class="dg-radio-button">
                            <span>
                              <div class="start-test-button">
                                <!-- <label class="switch"> -->
                                <b-form-checkbox
                                  name="check-button-companyPremium"
                                  id="bt3"
                                  v-model="companyPremium"
                                  size="lg"
                                ></b-form-checkbox>
                                <!-- </label> -->
                              </div>
                            </span>
                            <label for="bt3">
                              <div>
                                <h4>Company Basic infos</h4>
                                <span
                                  >Add company informations (exact number of
                                  employees, industry, tags, description,
                                  location)</span
                                >
                              </div>
                            </label>
                          </div>
                          <div class="dg-radio-button">
                            <span>
                              <div class="start-test-button">
                                <!-- <label class="switch"> -->
                                <b-form-checkbox
                                  name="check-button-companyFrench"
                                  id="bt4"
                                  v-model="companyFrench"
                                  size="lg"
                                ></b-form-checkbox>
                                <!-- </label> -->
                              </div>
                            </span>
                            <label for="bt4">
                              <div>
                                <h4>Company French infos</h4>
                                <span
                                  >For french company add SIRENE
                                  informations</span
                                >
                              </div>
                            </label>
                          </div>
                          <div class="dg-radio-button">
                            <span>
                              <div class="start-test-button">
                                <!-- <label class="switch"> -->
                                <b-form-checkbox
                                  name="check-button-companyFull"
                                  v-model="companyFull"
                                  id="bt5"
                                  size="lg"
                                ></b-form-checkbox>
                                <!-- </label> -->
                              </div>
                            </span>
                            <label for="bt5">
                              <div>
                                <h4>Company Full</h4>
                                <span
                                  >Add financial informations (money raised,
                                  funding stage) and website infos (technology,
                                  website traffic)</span
                                >
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="currentIndex === 2" class="dg-step-3">
                  <div class="dg-main-wrap">
                    <div>
                      <div class="dg-step-content-out">
                        <h4 class="dg-step-title">
                          Step 3 : Choose the Job change behavior
                        </h4>
                        <h4 class="dg-step-sub-title">
                          When Datagma detects that one of your contacts has
                          changed companies, what should we do?
                        </h4>
                        <div class="dg-checbox-button-wrapper">
                          <div class="dg-radio-button">
                            <span>
                              <div class="start-test-button">
                                <!-- <label class="switch"> -->
                                <b-form-checkbox
                                  name="check-button-companyFull"
                                  id="rbt2"
                                  v-model="
                                    createAssociateNewComapanyIfJobChangeDetect
                                  "
                                  size="lg"
                                ></b-form-checkbox>
                                <!-- </label> -->
                              </div>
                            </span>
                            <label for="rbt2">
                              <div>
                                <h4>
                                  When a new company is detected for a contact,
                                  do you want to create it and associate it?
                                </h4>
                                <span
                                  >If the company already exists, we will not
                                  create it and will only proceed to association
                                </span>
                              </div>
                            </label>
                          </div>
                          <div class="dg-radio-button">
                            <span>
                              <div class="start-test-button">
                                <!-- <label class="switch"> -->
                                <b-form-checkbox
                                  id="rbt3"
                                  name="check-button-companyPremium"
                                  v-model="taskCreationOptional"
                                  size="lg"
                                ></b-form-checkbox>
                                <!-- </label> -->
                              </div>
                            </span>
                            <label for="rbt3">
                              <h4>
                                When a new company is detected for a contact do
                                you want to create a task?
                              </h4>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div v-if="taskCreationOptional">
                        <div class="">
                          <div class="company-hadding">
                            <h4>Customize your task</h4>
                          </div>

                          <form>
                            <div class="heading-login">
                              <p>
                                Elevate user engagement with personalized
                                content, utilizing liquid variables like
                                ((contact_datagma_name)),
                                ((contact_datagma_linkedin_url)),
                                ((contact_datagma_former_company)) and
                                ((contact_datagma_new_company)).
                              </p>
                            </div>
                            <div class="form-group">
                              <label>Task Subject</label>
                              <input
                                v-model="taskData.taskSubjectData"
                                name="subject"
                                class="form-control"
                                placeholder="Enter your subject"
                                type="text"
                                id="taskSubject"
                              />
                              <div
                                v-if="
                                  taskCreationOptional &&
                                    $v.taskData.taskSubjectData.$error
                                "
                                class="invalid_feedback"
                              >
                                <span
                                  v-if="!$v.taskData.taskSubjectData.required"
                                  >*Please enter the task subject
                                </span>
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Task Body</label>
                              <textarea
                                v-model="taskData.taskBodyData"
                                name="body"
                                class="form-control"
                                placeholder="Enter your body"
                                id="taskBody"
                              ></textarea>
                              <div
                                v-if="
                                  taskCreationOptional &&
                                    $v.taskData.taskBodyData.$error
                                "
                                class="invalid_feedback"
                              >
                                <span v-if="!$v.taskData.taskBodyData.required"
                                  >*Please enter the task body
                                </span>
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Task Priority</label>
                              <b-form-select
                                v-model="taskData.taskPriorityData"
                                :options="taskPriority"
                              ></b-form-select>
                              <div
                                v-if="
                                  taskCreationOptional &&
                                    submitted &&
                                    $v.taskData.taskPriorityData.$error
                                "
                                class="invalid_feedback"
                              >
                                <span
                                  v-if="!$v.taskData.taskPriorityData.required"
                                  >*This filed is required</span
                                >
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Task Type</label>
                              <b-form-select
                                v-model="taskData.taskTypeData"
                                :options="taskType"
                              ></b-form-select>
                              <div
                                v-if="
                                  taskCreationOptional &&
                                    submitted &&
                                    $v.taskData.taskTypeData.$error
                                "
                                class="invalid_feedback"
                              >
                                <span v-if="!$v.taskData.taskTypeData.required"
                                  >*This filed is required</span
                                >
                              </div>
                            </div>
                            <div class="main-popup mt-3">
                              <div class="start-test-content">
                                <div class="start-test">
                                  <h5>Assign to contact’s owner</h5>
                                </div>

                                <div class="start-test-button">
                                  <label class="switch">
                                    <b-form-checkbox
                                      name="check-button-companyPremium"
                                      v-model="taskData.taskAssignToOwner"
                                      switch
                                      size="lg"
                                    ></b-form-checkbox>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="currentIndex === 3" class="dg-step-4">
                  <div class="dg-main-wrap">
                    <div>
                      <div class="dg-step-content-out">
                        <h4 class="dg-step-title">
                          Step 4: Give your enrichment a name
                        </h4>
                        <h4 class="dg-step-sub-title">
                          Enrich all your HubSpot contacts or enrich a specific
                          list
                        </h4>
                        <div class="dg-task-subject">
                          <h4 class="dg-step-title">Enrichment Name</h4>
                          <input
                            type="text"
                            class="input-field"
                            placeholder="Enrichment Name"
                            v-model="enrichmentName"
                          />
                          <div
                            v-if="$v.enrichmentName.$error && submitted"
                            class="invalid_feedback"
                          >
                            <span
                              class="invalid_feedback_validName"
                              v-if="!$v.enrichmentName.required"
                              >*Give your enrichment a name</span
                            >
                            <!-- Add other validation messages as needed -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    v-if="currentIndex === 3"
                    class="dg-settings-next-button"
                  >
                    <button @click="back" type="button" class="step-back-btn">
                      Back
                    </button>
                    <button @click="submitEnrich" type="button">
                      Submit
                    </button>
                  </div>

                  <div v-else class="dg-settings-next-button">
                    <div>
                      <div v-if="currentIndex === 0"></div>
                      <div v-else>
                        <button
                          @click="back"
                          type="button"
                          class="step-back-btn"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <button @click="next" type="button">Next</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>

      <!-- /////// -->

      <div v-if="hubspotJobIntegrated">
        <div class="company-new ">
          <div class="company-hadding">
            <h4>Detect Job Change for enrichment contact</h4>
          </div>

          <div class="main-popup main-hubspot-text">
            <div class="start-test-content">
              <div class="start-test">
                <h5>Detect Job Change For All Contacts</h5>
              </div>
              <div class="start-test-button">
                <label class="switch">
                  <b-form-checkbox
                    name="check-button-authEnrich"
                    v-model="detectJobChangesAllHubspotContact"
                    switch
                    size="lg"
                  ></b-form-checkbox>
                </label>
              </div>
            </div>

            <div class="start-test-content">
              <div class="start-test">
                <h5>Create Task if job change is detected</h5>
              </div>
              <div class="start-test-button">
                <label class="switch">
                  <b-form-checkbox
                    name="check-button-authEnrich"
                    v-model="createTaskOptional"
                    switch
                    size="lg"
                  ></b-form-checkbox>
                </label>
              </div>
            </div>
            <div
              class="or-btn text-center"
              v-if="!detectJobChangesAllHubspotContact"
            >
              <span>OR</span>
            </div>

            <div class="enrich-list" v-if="!detectJobChangesAllHubspotContact">
              <label>Detect Job Change By List</label>
              <div v-if="hubspotContactList && hubspotContactList.length > 0">
                <v-select
                  v-model="selectedContactListForJobChange"
                  label="name"
                  placeholder="Select List"
                  :reduce="(option) => option.listId"
                  :options="hubspotContactList"
                  multiple
                ></v-select>
              </div>
              <div v-else>
                <span> No Contact list Found</span>
              </div>
            </div>
          </div>

          <div class="start-test-content-new">
            <div class="start-test-cancel">
              <button @click="closeJobChangePop()">Cancel</button>
            </div>

            <div class="start-test-submit">
              <b-button
                :disabled="isJobChangeSubmitDisable()"
                @click="detectJobChange()"
              >
                Submit
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="enrichmentPermission">
        <div class="company-new">
          <div class="company-hadding">
            <h4>Customize your task</h4>
          </div>

          <form>
            <div class="heading-login">
              <p>
                Elevate user engagement with personalized content, utilizing
                liquid variables like ((contact_datagma_name)),
                ((contact_datagma_linkedin_url)),
                ((contact_datagma_former_company)) and
                ((contact_datagma_new_company)).
              </p>
            </div>
            <div class="form-group">
              <label>Task Subject</label>
              <input
                v-model="taskSubjectData"
                name="subject"
                class="form-control"
                placeholder="Enter your subject"
                type="text"
                id="taskSubject"
              />
            </div>
            <div class="form-group">
              <label>Task Body</label>
              <textarea
                v-model="taskBodyData"
                name="body"
                class="form-control"
                placeholder="Enter your body"
                id="taskBody"
              ></textarea>

    
            </div>
          </form>

          <div class="start-test-content-new">
            <div class="start-test-cancel">
              <button @click="closeJobChange()">Cancel</button>
            </div>

            <div class="start-test-submit">
              <b-button @click="creatTaskData()">
                Submit
              </b-button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <b-toast id="my-toast" variant="success" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <img src="@/assets/images/icons/integration-connected-icon.svg" />
          <p class="mr-auto">Your app successfully {{ notifactionShow }}</p>
        </div>
      </template>
    </b-toast>
    <b-toast id="my-toast-hubspot" variant="success" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <img src="@/assets/images/icons/integration-connected-icon.svg" />

          <p class="mr-auto">
            We are processing the job change in your HubSpot. Tasks will be
            created in few minutes.
          </p>
        </div>
      </template>
    </b-toast>
    <b-toast id="my-toast-connect-hubspot" variant="success" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <img src="@/assets/images/icons/integration-connected-icon.svg" />
          <p class="mr-auto">
            Please connect the hubspot to detect job changes
          </p>
        </div>
      </template>
    </b-toast>
    <b-toast id="my-toast-connect-hubspot-enrichment" variant="success" solid>
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <img src="@/assets/images/icons/integration-connected-icon.svg" />
          <p class="mr-auto">
            Please connect the hubspot to Start enrichment
          </p>
        </div>
      </template>
    </b-toast>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import {
  PossibleIntegrations,
  INTEGRATED_API_IDS,
  // newPossibleIntegrations,
} from "../../../store/constant/intergration.type.js";
import {
  REDIRECT_CODE,
  REDIRECT_INTEGRATION_NAME,
  PUSH_TO_HUBSPOT,
  HUBSPOT_DETECT_JOB_CHANGES,
} from "../../../store/constant/mutations.type";
import UtilsService from "../../common/services/utils.services";
import {
  ADD_INTEGRATION,
  GET_CONNECTIONS,
  GET_HUBSPOT_CONTACT_LIST,
  REMOVE_INTEGRATION,
  HUBSPOT_JOB_CHANGE_INTEGRATION,
  START_HUBSPOT_ENRICHMENT,
  UPDATE_INTEGRATION,
  UPDATE_TASK_DATA,
  GET_ENRICH_CONTACT_LIST,
} from "../../../store/constant/actions.type.js";
import { loadingService } from "../../common/services/loading.service";
import { analyticsService } from "../../common/services/analytics.service.js";
import Vue from "vue";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

export default {
  name: "userProfile",
  data() {
    return {
      listType: "Static",
      enrichmentName: "",
      currentIndex: 0,
      steps: Array.from({ length: 4 }).fill(""),
      user_detail: {},
      possibleIntegrations: [
        ...PossibleIntegrations,
        // ...newPossibleIntegrations,
      ],
      taskData: {
        taskPriorityData: null,
        taskTypeData: null,
        taskSubjectData: null,
        taskBodyData: null,
        taskAssignToOwner: false,
      },
      INTEGRATED_API_IDS: INTEGRATED_API_IDS,
      fullPage: false,
      keyValue: "",
      enrichAllHubspotContact: false,
      detectJobChangesAllHubspotContact: false,
      enrichAlreadyEnrichedContacts: false,
      deepTraffic: false,
      enrichFutureContact: false,
      enrichAllExistingContact: false,
      createTaskOptional: false,
      createAssociateNewComapanyIfJobChangeDetect: false,
      hubspotContactList: [],
      loader: "bars",
      notifactionShow: "",
      hubspotIntegrated: false,
      hubspotSetting: false,
      stepFormData: Number,
      enrichmentPermission: false,
      hubspotJobIntegrated: false,
      companyPremium: false,
      companyFrench: false,
      companyFull: false,
      autoEnrich: false,
      pushHubspot: false,
      showTaskForm: false,
      taskCreationOptional: false,
      taskAssignToOwner: false,
      userCredit: 0,
      // taskSubjectData: "Job Change Detected",
      // taskBodyData:
      // "((contact_datagma_name)) : - Previously working at ((contact_datagma_former_company)) -Now Working at ((contact_datagma_new_company)) - LinkedIn Profile: ((contact_datagma_linkedin_url))",
      hubspotJobChangeDetect: false,
      selectedContactList: [],
      selectedContactListWithName: "",
      selectedContactListForJobChange: [],
      task_creation_optional: false,
      taskSubjectError: null,
      taskBodyError: null,
      formDirty: false,
      taskPriority: [
        { value: "LOW", text: "LOW" },
        { value: "MEDIUM", text: "MEDIUM" },
        { value: "HIGH", text: "HIGH" },
      ],
      taskType: [
        { value: "EMAIL", text: "EMAIL" },
        { value: "CALL", text: "CALL" },
        { value: "TODO", text: "TODO" },
      ],
      // taskPriorityData: "",
      // taskTypeData: "",
      submitted: false,
    };
  },
  validations: {
    enrichmentName: { required },
    taskData: {
      taskPriorityData: { required },
      taskTypeData: { required },
      taskSubjectData: { required },
      taskBodyData: { required },
      taskAssignToOwner: { required },
    },
  },
  methods: {
    // next() {
    //   // Increment the currentIndex to show the next div
    //   this.$v.$touch();
    //   this.currentIndex = (this.currentIndex + 1) % 4; // Assuming you have 4 divs
    // },

    next() {
      if (this.currentIndex === 2) {
        this.$v.$touch();
        if (
          this.$v.$pending ||
          this.$v.taskData.$pending ||
          this.$v.taskData.taskBodyData.$error ||
          this.$v.taskData.taskSubjectData.$error
        ) {
          console.log("Form is not valid. Cannot proceed to the next step.");
        } else {
          this.currentIndex = (this.currentIndex + 1) % 4; // Assuming you have 4 divs

          let element = document.getElementById('C4');
            if (element) {
             element.scrollIntoView({ behavior: 'smooth' });
         }
        }
      } else {
        this.currentIndex = (this.currentIndex + 1) % 4; // Assuming you have 4 divs
      }
    },

    back() {
      // Increment the currentIndex to show the next div
      this.currentIndex = (this.currentIndex - 1) % 4; // Assuming you have 4 divs
      let element = document.getElementById('C4');
            if (element) {
             element.scrollIntoView({ behavior: 'smooth' });
         }
    },
    submitEnrich() {
      if (this.currentIndex === 3) {
        this.submitted = true;
        this.$v.$touch();
        if (
          this.$v.$pending ||
          this.$v.enrichmentName.$pending ||
          this.$v.enrichmentName.$error
        ) {
          console.log("Form is not valid. Cannot proceed to the next step.");
        } else {
          this.hubspotSetting = false;
          this.currentIndex = 0;
          this.startEnrichment();
        }
      } else {
        this.submitted = true;
        this.hubspotSetting = false;
        this.currentIndex = 0;
        this.startEnrichment();
      }
    },

    navigateToDiscover(key) {
      if (key == this.INTEGRATED_API_IDS.zapier) {
        window.open("https://datagma.com/zapier", "_blank").focus();
      } else if (key == this.INTEGRATED_API_IDS.integromat) {
        window
          .open(
            "https://www.integromat.com/en/apps/invite/59bdc3279e540031f92e5c3141bcdece",
            "_blank"
          )
          .focus();
      } else if (key == this.INTEGRATED_API_IDS.captaindata) {
        window.open("https://www.captaindata.co/", "_blank").focus();
      } else if (key == this.INTEGRATED_API_IDS.leadjet) {
        window.open("https://www.leadjet.io/", "_blank").focus();
      } else if (key == this.INTEGRATED_API_IDS.linkedin) {
        window
          .open(
            "https://chrome.google.com/webstore/detail/datagma/jcpkgjfbhhglonendnnmicgicebbemjd/",
            "_blank"
          )
          .focus();
      } else if (key == this.INTEGRATED_API_IDS.n8n) {
        window.open(this.mailToN8n);
      } else if (key == this.INTEGRATED_API_IDS.clay) {
        window.open("https://clay.run", "_blank").focus();
      }
    },
    closeModal() {
      this.$modal.hide("my-modal");
    },
    disconnecthubspot() {
      console.log("Disconnected", this.keyValue);
      this.onDeleteToken("hubspot");
      this.$modal.hide("my-modal");
    },
    onSelectIntegration(value) {
      if (value.detectChanges) {
        localStorage.setItem(HUBSPOT_DETECT_JOB_CHANGES, "true");
      }
      this.onOAUTH2Connect(value);
    },
    onSelectIntegrationModalToggle(value) {
      if (value?.chacked === true) {
        this.$modal.show("my-modal");
      } else {
        this.$modal.hide("my-modal");
      }
    },
    onOAUTH2Connect(info) {
      window.open(info.intAPIInfo.CONNECT_URL, "_self");
    },
    closePop() {
      this.hubspotIntegrated = !this.hubspotIntegrated;
    },
    closePopStep() {
      this.hubspotSetting = !this.hubspotSetting;
      this.currentIndex = 0;
      this.resetForm();
    },

    closeJobChangePop() {
      this.hubspotJobIntegrated = !this.hubspotJobIntegrated;
    },
    closeJobChange() {
      this.enrichmentPermission = false;
    },
    creatTaskData() {
      // let taskData = {
      //   task_body: this.taskBodyData,
      //   task_subject: this.taskSubjectData,
      // };
      let taskData = {};
      this.$store
        .dispatch(UPDATE_TASK_DATA, taskData)
        .then(() => {
          loadingService.setloader(false);
          this.enrichmentPermission = false;
        })
        .catch((err) => {
          loadingService.setloader(false);
          this.flashMessage.error({
            title: "Error",
            message: err.data,
          });
        });
    },
    formatTaskdata(value) {
      if (value == "title") {
        this.taskTitleData = this.taskTitleData
          .replace(/\s+/g, "_")
          .toLowerCase();
      } else if (value == "body") {
        // this.taskBodyData = this.taskBodyData
        //   .replace(/\s+/g, "_")
        //   .toLowerCase();
      } else if (value == "subject") {
        // this.taskSubjectData = this.taskSubjectData
        //   .replace(/\s+/g, "_")
        //   .toLowerCase();
      }
    },
    getIntegrationCode() {
      loadingService.setloader(true);
      // const code = UtilsService.getQueryStringValue("code");
      // const integration = UtilsService.getQueryStringValue("integration");
      this.userCredit = this.user_detail?.currentCredit;
      if (
        localStorage.getItem(REDIRECT_CODE) &&
        localStorage.getItem(REDIRECT_INTEGRATION_NAME) &&
        localStorage.getItem(REDIRECT_CODE) !== undefined &&
        localStorage.getItem(REDIRECT_INTEGRATION_NAME) !== undefined
      ) {
        this.$store
          .dispatch(ADD_INTEGRATION, {
            code: localStorage.getItem(REDIRECT_CODE),
            integration_name: localStorage.getItem(REDIRECT_INTEGRATION_NAME),
          })
          .then((data) => {
            loadingService.setloader(false);
            if (data) {
              this.getConnections();
              this.notifactionShow = PossibleIntegrations[0].connected;
              if (UtilsService.getQueryStringValue("code") !== "") {
                this.$router.push({ name: "userIntegration" });
              }
              if (
                localStorage.getItem(REDIRECT_INTEGRATION_NAME) == "hubspot"
              ) {
                if (
                  localStorage.getItem(PUSH_TO_HUBSPOT) &&
                  localStorage.getItem(PUSH_TO_HUBSPOT) == "true"
                ) {
                  this.pushHubspot = true;
                }
                if (
                  localStorage.getItem(HUBSPOT_DETECT_JOB_CHANGES) &&
                  localStorage.getItem(HUBSPOT_DETECT_JOB_CHANGES) == "true"
                ) {
                  this.hubspotJobChangeDetect = true;
                }
                this.hubspotIntegrated = true;
              } else if (
                localStorage.getItem(REDIRECT_INTEGRATION_NAME) == "pipedrive"
              ) {
                analyticsService.connectPipeDrive(this.userCredit);
              }
              this.$bvToast.show("my-toast");
              localStorage.removeItem(REDIRECT_CODE);
              localStorage.removeItem(REDIRECT_INTEGRATION_NAME);
              localStorage.removeItem(PUSH_TO_HUBSPOT);
              localStorage.removeItem(HUBSPOT_DETECT_JOB_CHANGES);
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            localStorage.removeItem(REDIRECT_INTEGRATION_NAME);
            localStorage.removeItem(PUSH_TO_HUBSPOT);
            localStorage.removeItem(HUBSPOT_DETECT_JOB_CHANGES);
            localStorage.removeItem(REDIRECT_CODE);
            this.$router.push({ name: "userIntegration" });
            if (err && err.data && err.data.code) {
              this.flashMessage.error({
                title: "Error",
                message: err.data.description,
              });
            }
          });
      }
    },
    getConnections() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_CONNECTIONS)
        .then((data) => {
          loadingService.setloader(false);
          if (data && data.data.length > 0) {
            let hubspotFinded = false;
            // this.task_creation_optional = true;
            if (this.task_creation_optional) {
              this.showTaskForm = true;
            } else {
              this.showTaskForm = false;
            }
            this.possibleIntegrations.forEach((element) => {
              element.chacked = false;
              data.data.forEach((ele) => {
                if (ele.key === element.key) {
                  element.chacked = true;
                } else if (ele.key == "hubspot" && !hubspotFinded) {
                  hubspotFinded = true;
                }
              });
            });
            if (hubspotFinded) {
              this.gethubspotContactList();
            }
          } else {
            this.possibleIntegrations.forEach((element) => {
              element.chacked = false;
            });
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          if (err && err.data && err.data.message) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error Message",
              message: err.data.message,
            });
          }
        });
    },
    gethubspotContactList() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_HUBSPOT_CONTACT_LIST)
        .then((data) => {
          loadingService.setloader(false);
          if (data) {
            let parsedData = JSON.parse(data);
            if (parsedData && parsedData.lists && parsedData.lists.length > 0) {
              this.hubspotContactList = parsedData.lists;
              this.selectedContactList = [this.hubspotContactList[0].listId];
            } else {
              this.hubspotContactList = [];
              this.selectedContactList = [];
            }
          } else {
            this.hubspotContactList = [];
            this.selectedContactList = [];
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          if (err && err.data && err.data.message) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error Message",
              message: err.data.message,
            });
          }
        });
    },
    onDeleteToken(token) {
      if (token !== null) {
        loadingService.setloader(true);
        this.$store
          .dispatch(REMOVE_INTEGRATION, { integration_name: token })
          .then((data) => {
            if (data) {
              loadingService.setloader(false);
              this.getConnections();
              this.notifactionShow = PossibleIntegrations[0].disconnected;
              this.$bvToast.show("my-toast");
            }
          })
          .catch((err) => {
            loadingService.setloader(false);
            if (err && err.data && err.data.message) {
              this.flashMessage.error({
                title: "Error Message",
                message: err.data.message,
              });
            }
          });
      }
    },

    getListNameById(listId) {
      const list = this.hubspotContactList.find(
        (list) => list.listId === listId
      );
      return list ? list.name : undefined;
    },

    createArrayOfSelectedList(listIds) {
      const listArray = listIds.map((id) => ({
        id,
        name: this.getListNameById(id),
      }));

      return listArray;
    },

    startEnrichment() {
      let selectedListStringArray = this.createArrayOfSelectedList(
        this.selectedContactList
      );
      selectedListStringArray = JSON.stringify(selectedListStringArray);
      // if (
      //   this.taskSubjectData == "" ||
      //   this.taskBodyData == "" ||
      //   this.taskPriorityData == "" ||
      //   this.taskTypeData == ""
      // ) {
      //   this.flashMessage.error({
      //     message: "Task Subject and Task Body are required.",
      //   });
      //   return;
      // }
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let email = localStorage.getItem("user_email");
      let userCredit = this.user_detail?.currentCredit;
      let segmentData = {
        email: email,
        userId: email,
        pushOnHubspot: this.pushHubspot,
        autoEnrich: true,
        userCredit,
      };
      console.log("segmentData", segmentData);
      // if (this.autoEnrich) {
      segmentData["companyPremium"] = this.companyPremium;
      segmentData["companyFrench"] = this.companyFrench;
      segmentData["companyFull"] = this.companyFull;
      segmentData["deepTraffic"] = this.deepTraffic;
      // }
      analyticsService.connectHubspot(this.user_detail, segmentData);
      delete segmentData.userCredit;
      // if (this.autoEnrich) {
      let enrichmentPayload = {
        enrichFutureContact: this.enrichFutureContact,
        enrichAllExistingContact: this.enrichAllExistingContact,
        companyPremium: this.companyPremium,
        companyFrench: this.companyFrench,
        companyFull: this.companyFull,
        listType: this.listType,
        enrichmentName: this.enrichmentName,
        integration_name: "hubspot",
        createAssociateNewComapanyIfJobChangeDetect: this
          .createAssociateNewComapanyIfJobChangeDetect,
        taskCreationOptional: this.taskCreationOptional,
        enrichAllHubspotContact: this.enrichAllHubspotContact,
        selectedContactList: this.selectedContactList,
        selectedListJsonString: selectedListStringArray,

        // enrichAlreadyEnrichedContacts: this.enrichAlreadyEnrichedContacts,
        enrichAlreadyEnrichedContacts: true,
        deepTraffic: this.deepTraffic,
        taskPriorityData: this.taskData.taskPriorityData,
        taskTypeData: this.taskData.taskTypeData,
        taskAssignToOwner: this.taskData.taskAssignToOwner,
      };
      if (this.taskCreationOptional) {
        enrichmentPayload.taskBodyData = this.taskData.taskBodyData;
        enrichmentPayload.taskSubjectData = this.taskData.taskSubjectData;
        enrichmentPayload.taskPriorityData = this.taskData.taskPriorityData;
        enrichmentPayload.taskTypeData = this.taskData.taskTypeData;
        enrichmentPayload.taskAssignToOwner = this.taskData.taskAssignToOwner;
      } else {
        enrichmentPayload.taskBodyData = null;
        enrichmentPayload.taskSubjectData = null;
        enrichmentPayload.taskPriorityData = null;
        enrichmentPayload.taskTypeData = null;
        enrichmentPayload.taskAssignToOwner = null;
      }
      if (this.enrichAllHubspotContact) {
        enrichmentPayload["selectedContactList"] = [];
        enrichmentPayload["selectedListJsonString"] = "";
      }
      loadingService.setloader(true);
      console.log(
        "enrichmentPayloadenrichmentPayloadenrichmentPayload>>>>",
        enrichmentPayload
      );
      this.$store
        .dispatch(START_HUBSPOT_ENRICHMENT, enrichmentPayload)
        .then((data) => {
          if (data && data.code == "success") {
            localStorage.setItem("showW", true);
            this.flashMessage.success({
              message: data.description,
            });

            this.hubspotIntegrated = false;
            this.createAssociateNewComapanyIfJobChangeDetect = false;
            this.taskCreationOptional = false;
            this.getEnrichmentData(email);
            this.resetForm();
          } else if (data.code == "Info") {
            this.resetForm();
            loadingService.setloader(false);
            this.flashMessage.error({
              message: data.description,
            });
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          this.hubspotIntegrated = false;
          if (err && err.data && err.data.message) {
            this.flashMessage.error({
              message: err.data.message,
            });
          }
        });
      // } else {
      //   this.hubspotIntegrated = false;
      // }
    },
    resetForm() {
      console.log("entersssss in reset");
      this.listType = "Static";
      this.enrichmentName = " ";
      this.companyFrench = false;
      this.companyPremium = false;
      this.companyFull = false;
      this.createAssociateNewComapanyIfJobChangeDetect = false;
      this.taskCreationOptional = false;
      this.enrichAllHubspotContact = false;
      this.selectedContactList = [1];
      this.enrichAlreadyEnrichedContacts = false;
      this.deepTraffic = false;
      this.taskData.taskPriorityData = "LOW";
      this.taskData.taskTypeData = "EMAIL";
      this.taskData.taskAssignToOwner = false;
      this.taskData.taskBodyData =
        "((contact_datagma_name)) : - Previously working at ((contact_datagma_former_company)) -Now Working at ((contact_datagma_new_company)) - LinkedIn Profile: ((contact_datagma_linkedin_url))";
      this.taskData.taskSubjectData = "Job Change Detected";
      this.submitted = false;

      // Reset the validation state
      this.$v.$reset();
    },

    getEnrichmentData(email) {
      let payload = {
        offset: 10,
        page: 1,
      };
      this.$store
        .dispatch(GET_ENRICH_CONTACT_LIST, payload)
        .then((data) => {
          loadingService.setloader(false);
          if (data.status == "success" && data.data.data.length > 0) {
            let enrichmentList = data.data.data;
            let inProgressContact = enrichmentList.filter(
              (x) => x.status == "In Progress" && x.email == email
            );
            this.$store.dispatch("changeApitime", 1);
            this.$router.push({
              name: "enrich-details-contact",
              params: { id: inProgressContact[0].id },
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    detectJobChange() {
      analyticsService.connectHubSpotJobChange(this.user_detail);
      let detectJobChangePayload = {
        detectJobChangesAllHubspotContact: this
          .detectJobChangesAllHubspotContact,
        selectedContactListForJobChange: this.selectedContactListForJobChange,
        createTaskOptional: this.createTaskOptional,
      };
      if (this.detectJobChangesAllHubspotContact) {
        detectJobChangePayload["selectedContactListForJobChange"] = [];
      }
      loadingService.setloader(true);
      this.$store
        .dispatch(HUBSPOT_JOB_CHANGE_INTEGRATION, detectJobChangePayload)
        .then(() => {
          loadingService.setloader(false);
          this.hubspotJobIntegrated = false;
          this.$bvToast.show("my-toast-hubspot");
        })
        .catch((err) => {
          loadingService.setloader(false);
          this.hubspotJobIntegrated = false;
          if (err && err.data) {
            if (err.data.description && err.data.code) {
              this.flashMessage.error({
                title: "Error",
                message: err.data.description,
              });
            } else if (typeof err.data == "string") {
              let error = JSON.parse(err.data);
              if (error.message && error.message != "") {
                this.flashMessage.error({
                  title: "Error",
                  message: error.message,
                });
              }
            }
          }
        });
      // } else if (!value.chacked) {
      //   this.$bvToast.show("my-toast-connect-hubspot");
      // }
    },
    openEnrichmentPopUp(value) {
      if (value.chacked) {
        this.hubspotIntegrated = true;
        this.pushHubspot = false;
        this.hubspotJobChangeDetect = false;
      } else {
        this.$bvToast.show("my-toast-connect-hubspot-enrichment");
      }
    },
    openEnrichmentSettingsPopUp(value) {
      if (value.chacked) {
        this.hubspotSetting = true;
        this.pushHubspot = false;
        this.hubspotJobChangeDetect = false;
      } else {
        this.$bvToast.show("my-toast-connect-hubspot-enrichment");
      }
    },

    openJobChangePopUp() {
      if (this.enrichmentPermission) {
        this.enrichmentPermission = false;
      } else {
        this.enrichmentPermission = true;
      }
    },
    showDataSwicth(value) {
      loadingService.setloader(true);
      if (value) {
        this.task_creation_optional = true;
      } else {
        this.task_creation_optional = false;
      }
      this.showTaskForm = !this.showTaskForm;
      this.$store
        .dispatch(UPDATE_INTEGRATION, this.task_creation_optional)
        .then((data) => {
          loadingService.setloader(false);
          this.task_creation_optional = data.task_creation_optional;
          this.flashMessage.success({
            message: data.result,
          });
        })
        .catch((err) => {
          loadingService.setloader(false);
          if (err && err.data && err.data.message) {
            this.flashMessage.error({
              message: err.data.message,
            });
          }
        });
    },
    isJobChangeSubmitDisable() {
      if (
        this.detectJobChangesAllHubspotContact ||
        this.selectedContactListForJobChange.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    isSubmitDisable() {
      if (this.enrichAllHubspotContact) {
        return false;
      } else if (this.selectedContactList.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },

  mounted() {
    debugger;
    this.possibleIntegrations = this.possibleIntegrations.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),

    mailToN8n: function() {
      let mailTo = null;
      mailTo = "mailto:raphael@datagma.com";

      let subject = "I want to use N8N Integration";
      subject = encodeURIComponent(subject);
      mailTo = `${mailTo}?subject=${subject}`;

      let emailBody =
        "Hello, %0D%0A %0D%0A" +
        "I want to use the N8N integration. %0D%0A%0D%0A" +
        "Can you help ? %0D%0A%0D%0A";

      mailTo = `${mailTo}&body=${emailBody}`;

      return mailTo;
    },
  },
  beforeMount() {
    this.user_detail = this.$store.getters.getstoreUser;
    this.taskData.taskPriorityData = this.taskPriority[0].value;
    this.taskData.taskTypeData = this.taskType[0].value;
    this.taskData.taskSubjectData = "Job Change Detected";
    this.taskData.taskBodyData =
      "((contact_datagma_name)) : - Previously working at ((contact_datagma_former_company)) -Now Working at ((contact_datagma_new_company)) - LinkedIn Profile: ((contact_datagma_linkedin_url))";
    this.$eventBus.$emit("callCreditMethod");
    this.getIntegrationCode();
    this.getConnections();
  },
};
</script>
<style scoped>
.integration-main {
  padding: 0px 65px;
  position: relative;
}

.integration-card {
  /* max-width: 290px; */
  padding: 18px 40px;
  background: #ffffff;
  box-shadow: 0px 34px 80px rgb(0 0 0 / 7%);
  border-radius: 4px;
  margin-top: 30px;
  /* min-height: 270px; */
  min-height: 320px;
}

.integration-card .title span {
  padding-left: 16px;
}

.integration-action-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.on-off-btn {
  position: relative;
  top: -10px;
}

.view_info {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.title img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.company-hadding h4 {
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 46px;
}

.modaldata ::v-deep .vm--modal {
  padding: 30px !important;
}

.enrich-head i {
  color: #003172;
  color: #fff;
}

.enrich-head {
  text-align: right;
  background: #3f6fcc;
  padding-right: 9px;
}

.action_button {
  width: 100%;
  padding: 6px 15px;
}

.enrichmodal {
  position: absolute;
  width: 100%;
  top: 56%;
  transform: translateY(-50%);
}

.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 29px;
  /* position: relative;
  display: inline-block;
  width: 54px;
  height: 31px; */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.start-test-content {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.start-test-content-new {
  display: flex;
  margin-bottom: 18px;
  gap: 14px;
}

.company-new {
  width: 34%;
  margin: 24px auto;
  box-shadow: 0px 47px 80px rgb(0 0 0 / 23%);
  padding: 35px;
  background: rgb(255 255 255);
  position: absolute;
  top: 118px;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 4px;
}

.start-test h5 {
  font-size: 19px;
  font-weight: 600;
}

.start-test p {
  font-size: 13px;
  color: rgb(165 173 186);
}

.button {
  background: rgb(37 137 255);
  color: rgb(255 255 255);
  margin: 0;
  padding: 1px 5px 3px;
  border-radius: 9px;
  justify-content: center;
  border: none;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
  font-size: 12px;
  margin-top: 13px;
}

.start-test-submit button {
  background: rgb(37 137 255);
  color: rgb(255 255 255);
  border: none;
  width: 78px;
  height: 38px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 18px;
}

.start-test-cancel button {
  background: rgb(255 255 255);
  color: rgb(0 49 114);
  border: none;
  width: 78px;
  height: 38px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 18px;
  border: 1px solid rgb(0 49 114);
}

.start-test-cancel button:hover {
  background: rgb(37 137 255);
  color: rgb(255 255 255);
  border: 1px solid rgb(37 137 255);
  transition: all 0.5s;
}

.or-btn span {
  font-size: 15px;
  font-weight: 500;
}

.enrich-list label {
  font-size: 17px;
  font-weight: 500;
}

.enrich-list ul.vs__dropdown-menu {
  font-size: 17px;
  font-weight: 600;
}

.text-blue {
  color: #2589ff;
}

.main-hubspot-text .start-test h5 {
  font-size: 15px;
}

.main-hubspot-text .start-test-content {
  align-items: center;
}

.text-blue:hover {
  text-decoration: underline;
}

.flex {
  display: flex;
  align-items: center;
}

.main-content {
  padding: 13px;
}

.heading-login p {
  font-size: 15px;
}

.company-new .form-group .invalid_feedback span {
  color: #ff000099;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.invalid_feedback_validName {
  color: #ff000099;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.enrichmodal.newenrichmodal {
  position: static;
  transform: none;
  margin-top: 50px;
}

.newenrichmodal .action_button {
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.enrich-head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
}
.modal_outer_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.company-new {
  z-index: 999;
  top: 18px;
}
@media only screen and (max-width: 769px) {
  .integration-main {
    padding: 0px 15px;
  }
}

@media only screen and (max-width: 1800px) {
  .integration-card {
    padding: 18px;
    min-height: 417px;
  }

  .title img {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 1500px) {
  .title {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .title img {
    width: 35px;
    height: 35px;
  }

  .title .font-lg-16 {
    font-size: 14px;
  }

  .integration-card .title span {
    padding-left: 5px;
  }

  .integration-card {
    padding: 18px;
    min-height: 417px;
  }
}

@media only screen and (max-width: 1499px) {
  .integration-main {
    padding: 0px 17px;
  }

  .title img {
    width: 27px;
    height: 27px;
  }

  .integration-card {
    padding: 18px;
    min-height: 502px;
  }
}
</style>
